@font-face {
    font-family: 'Museo Sans Condensed';
    src: url('MuseoSansCondensed-100Italic.eot');
    src: local('Museo Sans Condensed 100 Italic'), local('MuseoSansCondensed-100Italic'),
        url('MuseoSansCondensed-100Italic.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCondensed-100Italic.woff2') format('woff2'),
        url('MuseoSansCondensed-100Italic.woff') format('woff'),
        url('MuseoSansCondensed-100Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans Condensed';
    src: url('MuseoSansCondensed-700.eot');
    src: local('Museo Sans Condensed 700'), local('MuseoSansCondensed-700'),
        url('MuseoSansCondensed-700.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCondensed-700.woff2') format('woff2'),
        url('MuseoSansCondensed-700.woff') format('woff'),
        url('MuseoSansCondensed-700.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans Condensed';
    src: url('MuseoSansCondensed-300.eot');
    src: local('Museo Sans Condensed 300'), local('MuseoSansCondensed-300'),
        url('MuseoSansCondensed-300.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCondensed-300.woff2') format('woff2'),
        url('MuseoSansCondensed-300.woff') format('woff'),
        url('MuseoSansCondensed-300.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans Condensed';
    src: url('MuseoSansCondensed-500.eot');
    src: local('Museo Sans Condensed 500'), local('MuseoSansCondensed-500'),
        url('MuseoSansCondensed-500.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCondensed-500.woff2') format('woff2'),
        url('MuseoSansCondensed-500.woff') format('woff'),
        url('MuseoSansCondensed-500.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans Condensed';
    src: url('MuseoSansCondensed-500Italic.eot');
    src: local('Museo Sans Condensed 500 Italic'), local('MuseoSansCondensed-500Italic'),
        url('MuseoSansCondensed-500Italic.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCondensed-500Italic.woff2') format('woff2'),
        url('MuseoSansCondensed-500Italic.woff') format('woff'),
        url('MuseoSansCondensed-500Italic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans Condensed';
    src: url('MuseoSansCondensed-100.eot');
    src: local('Museo Sans Condensed 100'), local('MuseoSansCondensed-100'),
        url('MuseoSansCondensed-100.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCondensed-100.woff2') format('woff2'),
        url('MuseoSansCondensed-100.woff') format('woff'),
        url('MuseoSansCondensed-100.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans Condensed';
    src: url('MuseoSansCondensed-900Italic.eot');
    src: local('Museo Sans Condensed 900 Italic'), local('MuseoSansCondensed-900Italic'),
        url('MuseoSansCondensed-900Italic.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCondensed-900Italic.woff2') format('woff2'),
        url('MuseoSansCondensed-900Italic.woff') format('woff'),
        url('MuseoSansCondensed-900Italic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans Condensed';
    src: url('MuseoSansCondensed-900.eot');
    src: local('Museo Sans Condensed 900'), local('MuseoSansCondensed-900'),
        url('MuseoSansCondensed-900.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCondensed-900.woff2') format('woff2'),
        url('MuseoSansCondensed-900.woff') format('woff'),
        url('MuseoSansCondensed-900.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Museo Sans Condensed';
    src: url('MuseoSansCondensed-300Italic.eot');
    src: local('Museo Sans Condensed 300 Italic'), local('MuseoSansCondensed-300Italic'),
        url('MuseoSansCondensed-300Italic.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCondensed-300Italic.woff2') format('woff2'),
        url('MuseoSansCondensed-300Italic.woff') format('woff'),
        url('MuseoSansCondensed-300Italic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Museo Sans Condensed';
    src: url('MuseoSansCondensed-700Italic.eot');
    src: local('Museo Sans Condensed 700 Italic'), local('MuseoSansCondensed-700Italic'),
        url('MuseoSansCondensed-700Italic.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCondensed-700Italic.woff2') format('woff2'),
        url('MuseoSansCondensed-700Italic.woff') format('woff'),
        url('MuseoSansCondensed-700Italic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

